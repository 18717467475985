import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { screenSizes } from '../../lib/constants'

const Paragraph = styled.div`
    margin: 15px 0;
    line-height: 1.3;
    font-size: 1rem;

    @media (max-width: ${screenSizes.xl}px) {
        font-size: 0.9rem;
    }
`
const UnOrderedList = styled.ul`
    margin-top: 20px;
    display: block;

    li {
        list-style: circle;
        margin: 5px 0 0 30px;
    }

    li div.paragraph {
        margin: 0;
    }
`

const RichContent = ({ body }) => {
    const { i18n } = useTranslation()
    const currentLang = i18n.language

    if (body === null || body === undefined) {
        return ''
    }

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <strong>{text}</strong>,
        },
        renderNode: {
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                return (
                    <Link
                        className="inlineLink"
                        to={`/${node.data.target.fields.postType[currentLang]}/${node.data.target.fields.slug[currentLang]}`}
                    >
                        {children}
                    </Link>
                )
            },
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                return (
                    <img
                        src={node.data.target.fields.file[currentLang].url}
                        alt={node.data.target.fields.file[currentLang].fileName}
                    />
                )
            },
            // [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
            //   console.log("node: "+node)
            //   console.log("children: "+children)
            //   // return <UnOrderedList className={node.nodeType}>{children}</UnOrderedList>
            // },

            [BLOCKS.PARAGRAPH]: (node, children) => {
                if (node.nodeType === 'paragraph') {
                    return (
                        <Paragraph className={node.nodeType}>
                            {children}
                        </Paragraph>
                    )
                }
            },
            [BLOCKS.HEADING_1]: (node, children) => {
                return <h1 className={node.nodeType}>{children}</h1>
            },
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <UnOrderedList className={node.nodeType}>
                        {children}
                    </UnOrderedList>
                )
            },
        },
    }

    return <div>{documentToReactComponents(JSON.parse(body), options)}</div>
}

export default RichContent

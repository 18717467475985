import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Wrapper from '../../components/Wrapper'
import BgImage from '../../components/BgImage'
import Spacer from '../../uielements/Spacer'
import RichContent from '../../uielements/RichContent/richContent'
import InnerTitle from '../../uielements/InnerTitle'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import Slide from './Slide'

const AssetSlider = styled.div`
    position: relative;
    display: block;
    z-index: 9;
    margin-top: 20px;
`

const SingleSlide = styled.div``

const Asset = ({ data }) => {
    const settings = {
        dots: true,
        slidesToScroll: 1,
        className: 'center',
        centerMode: false,
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        arrows: true,
        autoplay: true,
    }

    const galleryItems = data.asset.gallery

    return (
        <Layout>
            <BgImage
                txt={data.asset.title}
                size="half"
                bgImg={data.asset.featuredImage.url}
            ></BgImage>
            <Spacer top={'10'} />
            <Wrapper>
                <InnerTitle title="ΠΕΡΙΓΡΑΦΗ ΚΑΤΗΓΟΡΙΑΣ" />
                <RichContent body={data.asset.body.raw} />
            </Wrapper>
            <Wrapper>
                <AssetSlider>
                    <Slider {...settings}>
                        {galleryItems.map((item) => (
                            <SingleSlide key={item.id} className="singleSlide">
                                <Slide bgImage={item.file.url}></Slide>
                            </SingleSlide>
                        ))}
                    </Slider>
                </AssetSlider>
            </Wrapper>
            <Spacer top={'10'} />
        </Layout>
    )
}

export const query = graphql`
    query SingleRental($slug: String) {
        asset: contentfulRentalCategories(slug: { eq: $slug }) {
            title
            slug
            node_locale
            excerpt {
                raw
            }
            body {
                raw
            }
            featuredImage {
                url
                title
                gatsbyImageData(
                    quality: 75
                    jpegProgressive: true
                    sizes: "1920, 960, 480"
                )
            }
            gallery {
                file {
                    url
                }
                gatsbyImageData(
                    quality: 75
                    jpegProgressive: true
                    sizes: "1920, 960, 480"
                )
            }
        }
    }
`

export default Asset
